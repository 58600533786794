import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoreService } from "src/app/core/core.service";
import { Item, Address, PaymentOptions, Combo } from "src/app/interfaces/cart";
import { CurrencyPipe } from "@angular/common";
import { ClientCart } from "src/app/interfaces/clientCart";
import { PurchaseResume } from "src/app/interfaces/purchase-resume";
import { CartService } from "src/app/services/cart.service";
import { GenericValidator } from "src/app/validators/generic-validator";
import { Constants } from "src/app/shared/constants.service";
import { CartCheckoutService } from "src/app/services/cart-checkout.service";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";
import {
  Installment,
  Installments,
  PayloadInstallments,
} from "src/app/interfaces/installments";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { SnotifyService } from "ng-snotify";

@Component({
  selector: "cm-cart-checkout",
  templateUrl: "./cart-checkout.component.html",
  styleUrls: ["./cart-checkout.component.scss"],
})
export class CartCheckoutComponent implements OnInit {
  siteUrl = environment.siteUrl;

  form: FormGroup = this.formBuilder.group({
    email: ["", [Validators.email, Validators.required]],
    name: ["", [Validators.required, Validators.minLength(5), Validators.pattern('[a-zA-ZÀ-ÖØ-öø-ÿ ]*'), GenericValidator.isValidName()]],
    birth: ["", Validators.required],
    cpf: ["", [Validators.required, GenericValidator.isValidCpf()]],
    phone: ["", Validators.required],
    profession: ["", Validators.required],
    cep: [""],
    uf: ["", Validators.required],
    number: ["", Validators.required],
    address: ["", Validators.required],
    city: ["", Validators.required],
    neighborhood: ["", Validators.required],
    complement: [""],
    card_number: [""],
    expire_date: [""],
    card_holder: [""],
    cvv: [""],
    installments: [""],
    cpf_holder: [""],
    holder_number: [""],
    email_holder: [""],
    cep_holder: [""],
    state_holder: [""],
    address_number_holder: [""],
    address_holder: [""],
    city_holder: [""],
    neighborhood_holder: [""],
    complement_holder: [""],
  });

  listaEstados = [
    { label: 'AC', value: 'AC' },
    { label: 'AL', value: 'AL' },
    { label: 'AP', value: 'AP' },
    { label: 'AM', value: 'AM' },
    { label: 'BA', value: 'BA' },
    { label: 'CE', value: 'CE' },
    { label: 'DF', value: 'DF' },
    { label: 'ES', value: 'ES' },
    { label: 'GO', value: 'GO' },
    { label: 'MA', value: 'MA' },
    { label: 'MT', value: 'MT' },
    { label: 'MS', value: 'MS' },
    { label: 'MG', value: 'MG' },
    { label: 'PA', value: 'PA' },
    { label: 'PB', value: 'PB' },
    { label: 'PR', value: 'PR' },
    { label: 'PE', value: 'PE' },
    { label: 'PI', value: 'PI' },
    { label: 'RJ', value: 'RJ' },
    { label: 'RN', value: 'RN' },
    { label: 'RS', value: 'RS' },
    { label: 'RO', value: 'RO' },
    { label: 'RR', value: 'RR' },
    { label: 'SC', value: 'SC' },
    { label: 'SP', value: 'SP' },
    { label: 'SE', value: 'SE' },
    { label: 'TO', value: 'TO' }
];
  displayTerms: boolean = false;
  captchaResponse: string;
  dialog = false;
  address: Address;

  paymentOptions: PaymentOptions = {
    allow_billet: false,
    allow_card: false,
    allow_pix: false,
  };

  allow_subscription: boolean = true;

  items: Item[] = [];
  combo: Combo[] = [];

  resumeCart: PurchaseResume = {
    subtotal: {
      label: "R$ 0,00",
      value: 0,
    },
    shipping: {
      label: "---",
      value: 0,
    },
    discountCombo: {
      label: "R$ 0,00",
      value: 0,
    },
    discountVoucher: {
      label: "R$ 0,00",
      value: 0,
    },
    total: {
      label: "R$ 0,00",
      value: 0,
    },
  };

  paymentResponse: any = {
    status: null,
    message: "",
    messageInvoice: "",
    processorCode: "",
    messagePayment: "",
    paymentObservation: "",
    qrCode: "",
    qrCodeUrl: "",
    expiresAt: "",
    codigoPromocional: ""
  };

  pgtAprovado:any = 'Aguardando Pagamento';
  codigo_promocional_200: string = "";

  currentTermsContent: any;
  paymentMethodTypeSelected: string = "";
  isMethodCardSelected: boolean = false;
  isMethodTicketSelected: boolean = false;
  isMethodPixSelected: boolean = false;
  isDifferentHolder: boolean = false;
  isDifferentHolderAddress: boolean = false;
  isCompletedPurchase: boolean = false;
  flipCard: boolean = false;
  showMsgCodeCopied: boolean = false;
  showModalCrossData: boolean = false;

  showImageTerm: boolean = false;
  showConfirmationOfNotUseImageTerm: boolean = false;
  showCheckoutButton: boolean = true;
  installments: Installment[] = [];
  loading: boolean = true;

  professions = [
    {
      name: "MÉDICO(A)",
    },
    {
      name: "ACADÊMICO DE MEDICINA",
    },
    {
      name: "ENFERMEIRO(A)",
    },
    {
      name: "OUTROS",
    },
  ];

  card = {
    card_number: "",
    card_holder: "",
    cvv: "",
    expire_date: "",
  };
  terms = {
    commitment: false,
    privacity: false,
    image: false,
    no_image_use: false,
  };

  cart_id: string = "";
  cross_options: any = {};
  checkout_response: any = {};

  @ViewChild("cardWrapper") cardWrapper: ElementRef;
  @ViewChild("codeBoleto") codeBoleto: ElementRef;
  @ViewChild("codePix") codePix: ElementRef;

  constructor(
    private _currencyPipe: CurrencyPipe,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private coreService: CoreService,
    private cartCheckoutService: CartCheckoutService,
    private route: ActivatedRoute,
    private _snotifyService: SnotifyService,
  ) {}

  ngOnInit() {

    this.cart_id = this.route.snapshot.params.id;

    let check = Number(this.cart_id);
    if (isNaN(check)) {
      let explode = this.cart_id.split("-");
      let link_checkout = `${this.siteUrl}/carrinho?link-inscricao=${
        explode[explode.length - 1]
      }`;
      window.location.href = link_checkout;
    }

    const cart = JSON.parse(window.sessionStorage.getItem("cart"));

    if (cart.items.length == 0) {
      this.cartCheckoutService
        .findCart(this.cart_id)
        .subscribe((response: Item[]) => {
          response.forEach((item) => {
            this.cartCheckoutService.addItemInCart(item);
          });

          this.setCheckoutData();

          if (
            !this.paymentOptions.allow_billet &&
            !this.paymentOptions.allow_card &&
            !this.paymentOptions.allow_pix
          ) {
            this.allow_subscription = false;
          }

          this.loading = false;
        });
    } else {
      this.setCheckoutData();

      if (
        !this.paymentOptions.allow_billet &&
        !this.paymentOptions.allow_card &&
        !this.paymentOptions.allow_pix
      ) {
        this.allow_subscription = false;
      }

      this.loading = false;
    }
  }



  disableFieldsIfNotEmpty() {
    if (this.form.controls["cep"].value) {
      this.form.controls["cep"].disable();
    }

    if (this.form.controls["address"].value) {
      this.form.controls["address"].disable();
    }

    if (this.form.controls["neighborhood"].value) {
      this.form.controls["neighborhood"].disable();
    }

    if (this.form.controls["city"].value) {
      this.form.controls["city"].disable();
    }

    if (this.form.controls["uf"].value) {
      this.form.controls["uf"].disable();
    }
  }

  setCheckoutData() {
    this.items = this.cartService.getItemsFromCart();
    this.combo = this.cartService.getComboFromCart();
    this.address = this.cartService.getAddressFromCart();
    this.paymentOptions = this.cartService.getPaymentOptionsFromCart();

    this.form.controls["cep"].setValue(this.address.shippingDestiny);

    if (this.address.status) {
      this.form.controls["address"].setValue(this.address.address);
      this.form.controls["neighborhood"].setValue(this.address.neighborhood);
      this.form.controls["city"].setValue(this.address.city);
      this.form.controls["uf"].setValue(this.address.uf);
    }

    this.calculateResumeSubTotal();
    this.calculateShipping();
    this.calculateDiscountTotal();
    this.calculateDiscountComboTotal();
    this.calculateResumeTotal();
    this.getTotalInstallments();
    this.disableFieldsIfNotEmpty();
  }

  changeMethodPayment(method: string) {
    if (method === "credit_card") {
      this.isMethodCardSelected = true;
      this.isMethodTicketSelected = false;
      this.isMethodPixSelected = false;
      this.paymentMethodTypeSelected = "credit_card";
      this.UpdateResumeValue();
      this.enableCardPaymentInputsValidators();
    }
    if (method === "invoice") {
      this.isMethodCardSelected = false;
      this.isMethodTicketSelected = true;
      this.isMethodPixSelected = false;
      this.paymentMethodTypeSelected = "invoice";
      this.isDifferentHolder = false;
      this.isDifferentHolderAddress = false;
      this.calculateDiscountTotal();
      this.calculateDiscountComboTotal();
      this.calculateResumeTotal();
      this.disableCardPaymentInputsValidators();
    }
    if (method === "pix") {
      this.isMethodCardSelected = false;
      this.isMethodTicketSelected = false;
      this.isMethodPixSelected = true;
      this.paymentMethodTypeSelected = "pix";
      this.UpdateResumeValue();
      this.calculateDiscountTotal();
      this.calculateDiscountComboTotal();
      this.calculateResumeTotal();
      this.disableCardPaymentInputsValidators();
    }
  }

  toggleDifferentHolderData() {
    this.isDifferentHolder = !this.isDifferentHolder;
    this.updateCardWrapperSize();

    if (this.isDifferentHolder) this.enableOtherHolderInputsValidators();
    else this.disableOtherHolderInputsValidators();
  }

  toggleDifferentHolderAddress() {
    this.isDifferentHolderAddress = !this.isDifferentHolderAddress;
    this.updateCardWrapperSize();

    if (this.isDifferentHolderAddress)
      this.enableOtherHolderAddressInputsValidators();
    else this.disableOtherHolderAddressInputsValidators();
  }

  changeFlipChard() {
    this.flipCard = !this.flipCard;
  }

  updateCardWrapperSize() {
    if (this.isDifferentHolder && !this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `740px`;
    } else if (!this.isDifferentHolder && this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `840px`;
    } else if (this.isDifferentHolder && this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `1040px`;
    } else if (!this.isDifferentHolder && !this.isDifferentHolderAddress) {
      this.cardWrapper.nativeElement.style.height = `540px`;
    }
  }

  formatExpireDate(validate: string) {
    let input = validate;

    input = input.replace(/\D/g, "");

    if (input.length > 2) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }

    this.form.get("expire_date").setValue(input);
    this.card.expire_date = input;
  }

  formatCardNumber(number: string) {
    number = number.replace(/(\d{4})(?=\d)/g, "$1 ");
    return number;
  }

  formatItemValue(value: number): string {
    return Number(value).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });
  }

  calculateResumeSubTotal() {
    const value = this.items.reduce(
      (previousValue, currentValue) => previousValue + currentValue.value,
      0
    );

    this.resumeCart.subtotal.value = value;

    this.resumeCart.subtotal.label = this.formatItemValue(value);
  }

  calculateShipping() {
    const shippingValues = this.items.map((value) => {
      if (!value.shippingTypes) {
        return 0;
      }

      const type = value.shippingTypes.find(
        (shipping) => shipping.type == value.shippingTypeSelected
      );

      return type ? type.description.shipping_value : 0;
    });

    const value = shippingValues.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    this.resumeCart.shipping.value = value;

    if (value != 0)
      this.resumeCart.shipping.label = this.formatItemValue(value);
  }

  calculateDiscountTotal() {
    let discountVoucher = 0;

    discountVoucher = this.items.reduce((previous, current) => {
      if (current.discount) {
        return previous + current.discount;
      }
      return previous;
    }, 0);

    if (discountVoucher) {
      this.resumeCart.discountVoucher.label =
        this.formatItemValue(discountVoucher);
      this.resumeCart.discountVoucher.value = discountVoucher;
    } else {
      this.resumeCart.discountVoucher.value = 0;
      this.resumeCart.discountVoucher.label = "---";
    }
  }

  calculateDiscountComboTotal() {
    const total = this.combo.reduce((previous, current) => {
      if (current.discount) {
        return previous + current.discount;
      }

      return previous;
    }, 0);

    if (total) {
      this.resumeCart.discountCombo.label = this.formatItemValue(total);
      this.resumeCart.discountCombo.value = total;
    }
  }

  calculateResumeTotal() {
    const total =
      this.resumeCart.shipping.value +
      this.resumeCart.subtotal.value -
      this.resumeCart.discountCombo.value -
      this.resumeCart.discountVoucher.value;

    this.resumeCart.total.value = total;

    this.resumeCart.total.label = this.formatItemValue(total);
  }

  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse["response"];
  }

  getTotalInstallments() {
    const items = this.cartService.getItemsFromCart();
    const courses = items.map((item) => {
      return { id: item.id, cuponId: item.coupom_id };
    });

    const data: any = {
      is_package: false,
      blackfriday: false,
      courses: courses,
      shippingValue: this.resumeCart.shipping.value,
      discount: this.resumeCart.discountCombo.value,
    };

    this.coreService.getInstallments(data).subscribe({
      next: (res: Installments[]) => {
        let inst = [];
        const installments = res.filter((value) => value.full_price == this.resumeCart.total.value);

        if (installments.length > 1) {
          inst = res.map((item) => {
            let data = {
              label: `${item.installment}x de ${this._currencyPipe.transform(
                item.installment_price,
                "R$"
              )} (${
               item.installment_fee ? "com juros" : "sem juros"
              }) Total ${this._currencyPipe.transform(item.full_price, "R$ ")} `,
              price: item.full_price,
              fee: item.installment_fee,
              value: item.installment,
            };
            return data;
          });
        } else {
          inst = res.map((item) => {
            let data = {
              label: `${item.installment}x de ${this._currencyPipe.transform(
                item.installment_price,
                "R$"
              )} (${
               item.installment == 1 ? "c/ desconto" : item.installment_fee ? "com juros" : "sem juros"
              }) Total ${this._currencyPipe.transform(item.full_price, "R$ ")} `,
              price: item.full_price,
              fee: item.installment_fee,
              value: item.installment,
            };
            return data;
          });
        }
        inst.sort(function (a, b) {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        });
        this.installments = inst;
        this.form.get("installments").setValue(1);
      },
      error: (err) => console.log(err),
    });
  }

  checkout() {
    if (!this.terms.privacity || !this.terms.commitment) {
      this.dialog = true;
      return;
    }

    let cart = this.cartService.getCart();

    if (!this.terms.image && !this.terms.no_image_use) {
      this.showConfirmationOfNotUseImageModal();
      return;
    }

    this.isCompletedPurchase = false;
    this.showCheckoutButton = false;

    const courses = this.items.map((value) => {
      return {
        id: value.id,
        coupom_id: value.coupom_id,
        shippingTypeSelected: value.shippingTypeSelected,
        shippingValue: value.shippingTypeSelected
          ? value.shippingTypes.find(
              (type) => type.type == value.shippingTypeSelected
            ).description.shipping_value
          : 0,
        shippingTime: value.shippingTime,
        shippingDestiny: value.shippingDestiny,
      };
    });

    let payload = {
      installments: this.form.get("installments").value
        ? this.form.get("installments").value
        : 1,
      captchaResponse: this.captchaResponse,
      courses: courses,
      origem: "curem",
      payment_method: this.paymentMethodTypeSelected,
      shippingValue: this.resumeCart.shipping.value,
      combo: cart["combo"],
      value_total: this.resumeCart.total.value,
    };

    payload["personal_data"] = {
      email: this.form.get("email").value,
      name: this.form.get("name").value,
      birth: this.form.get("birth").value,
      cpf: this.form.get("cpf").value,
      phone: this.form.get("phone").value,
      cep: this.form.get("cep").value,
      uf: this.form.get("uf").value.value,
      number: this.form.get("number").value,
      address: this.form.get("address").value,
      city: this.form.get("city").value,
      neighborhood: this.form.get("neighborhood").value,
      complement: this.form.get("complement").value,
      image_term_accepted: this.terms.image,
      profession: this.form.get("profession").value.name,
    };

    if (this.paymentMethodTypeSelected == "credit_card") {
      if (this.isDifferentHolder) {
        payload["credit_card"] = {
          cpf: this.form.get("cpf_holder").value,
          phone: this.form.get("holder_number").value,
          email: this.form.get("email_holder").value,
        };
      } else {
        payload["credit_card"] = {
          cpf: this.form.get("cpf").value,
          phone: this.form.get("phone").value,
          email: this.form.get("email").value,
        };
      }

      if (this.isDifferentHolderAddress) {
        payload["credit_card"]["address"] =
          this.form.get("address_holder").value;
        payload["credit_card"]["number"] = this.form.get(
          "address_number_holder"
        ).value;
        payload["credit_card"]["neighborhood"] = this.form.get(
          "neighborhood_holder"
        ).value;
        payload["credit_card"]["complement"] =
          this.form.get("complement_holder").value;
        payload["credit_card"]["cep"] = this.form.get("cep_holder").value;
        payload["credit_card"]["city"] = this.form.get("city_holder").value;
        payload["credit_card"]["uf"] = this.form.get("state_holder").value;
      } else {
        payload["credit_card"]["address"] = this.form.get("address").value;
        payload["credit_card"]["number"] = this.form.get("number").value;
        payload["credit_card"]["neighborhood"] =
          this.form.get("neighborhood").value;
        payload["credit_card"]["complement"] =
          this.form.get("complement").value;
        payload["credit_card"]["cep"] = this.form.get("cep").value;
        payload["credit_card"]["city"] = this.form.get("city").value;
        payload["credit_card"]["uf"] = this.form.get("uf").value.value;
      }

      payload["credit_card"]["name_in_card"] =
        this.form.get("card_holder").value;
      payload["credit_card"]["set_number"] = this.form.get("card_number").value;
      payload["credit_card"]["expire_date"] =
        this.form.get("expire_date").value;

      payload["credit_card"]["set_cvv_number"] = (this.form.get("cvv").value).replace(/_/g, "");
    }

    this.coreService
      .courseSubscription(payload)
      .pipe(
        catchError(() => {
          this.cartCheckoutService.showErrorMessagePopup();
          this.showCheckoutButton = true;
          return throwError("Erro ao tentar realizar a compra");
        })
      )
      .subscribe((response) => {

        if (response.status === 'error' && response.message === 'Cupom inválido! Este cupom está cadastrado para outro CPF.') {

          this._snotifyService.error(
            response.message,
            "",
            {
              timeout: 3000,
              showProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              position: "centerTop",
            }
          );

          this.isCompletedPurchase = false;
          this.showCheckoutButton = true;
          return;
        }

        this.checkout_response = response;

        this.cartCheckoutService.deleteCart(this.cart_id);

        this.paymentResponse.status = response.status;
        this.paymentResponse.message = response.message;
        this.paymentResponse.messageInvoice = response.messageInvoice || "";
        if (response.processorCode) {
          this.paymentResponse.processorCode = this.formatTicketCode(
            response.processorCode
          );
        }

        if (response.codigo_promocional) {
          this.paymentResponse.codigoPromocional = response.codigo_promocional;
        }

        if (!!response.message_payment) {
          this.paymentResponse.messagePayment = response.message_payment;
          this.paymentResponse.paymentObservation = response.payment_observation;
          this.paymentResponse.qrCode = response.qrCode;
          this.paymentResponse.qrCodeUrl = response.qrCodeUrl;
          this.paymentResponse.expiresAt = response.expiresAt;
        }

        if(this.paymentMethodTypeSelected == "credit_card" || this.paymentMethodTypeSelected == "pix") {

          setTimeout(() => {
            this.verificaPagamento(response.subscription_id);
          }, 10000);

        } else {

          setTimeout(() => {
            this.showModalCrossData = true;
          }, 1000);
        }

        this.isCompletedPurchase = true;
        this.showCheckoutButton = true;

        this.cartService.deleteAllItemsFromCart();
      });
  }

  async verificaPagamento(idVenda) {

    let response: any = await this.cartService.verificaPagamento(idVenda).toPromise();

    if(response.status == 'Aguardando Pagamento') {

      setTimeout(() => {
        this.verificaPagamento(idVenda);
      }, 7000);

    } else {

      this.pgtAprovado = response.status;
      this.codigo_promocional_200 = response.codigo_promocional;

      setTimeout(() => {
        this.showModalCrossData = true;
      }, 1000);

    }
  }

  enableCardPaymentInputsValidators() {
    this.form.get("card_number").enable();
    this.form.get("expire_date").enable();
    this.form.get("card_holder").enable();
    this.form.get("cvv").enable();

    this.form.get("card_number").setValidators(Validators.required);
    this.form.get("expire_date").setValidators(Validators.required);
    this.form
      .get("card_holder")
      .setValidators([Validators.required, Validators.minLength(5)]);
    this.form.get("cvv").setValidators([Validators.required]);
  }

  disableCardPaymentInputsValidators() {
    this.form.get("card_number").disable();
    this.form.get("expire_date").disable();
    this.form.get("card_holder").disable();
    this.form.get("cvv").disable();
  }

  enableOtherHolderInputsValidators() {
    this.form.get("cpf_holder").enable();
    this.form.get("holder_number").enable();
    this.form.get("email_holder").enable();

    this.form
      .get("cpf_holder")
      .setValidators([Validators.required, GenericValidator.isValidCpf()]);
    this.form.get("holder_number").setValidators([Validators.required]);
    this.form.get("email_holder").setValidators([Validators.required]);
  }

  disableOtherHolderInputsValidators() {
    this.form.get("cpf_holder").disable();
    this.form.get("holder_number").disable();
    this.form.get("email_holder").disable();
  }

  enableOtherHolderAddressInputsValidators() {
    this.form.get("cep_holder").enable();
    this.form.get("address_holder").enable();
    this.form.get("address_number_holder").enable();
    this.form.get("neighborhood_holder").enable();
    this.form.get("city_holder").enable();
    this.form.get("state_holder").enable();

    this.form.get("cep_holder").setValidators([Validators.required]);
    this.form.get("address_holder").setValidators([Validators.required]);
    this.form.get("address_number_holder").setValidators([Validators.required]);
    this.form.get("neighborhood_holder").setValidators([Validators.required]);
    this.form.get("city_holder").setValidators([Validators.required]);
    this.form.get("state_holder").setValidators([Validators.required]);
  }

  disableOtherHolderAddressInputsValidators() {
    this.form.get("cep_holder").disable();
    this.form.get("address_holder").disable();
    this.form.get("address_number_holder").disable();
    this.form.get("neighborhood_holder").disable();
    this.form.get("city_holder").disable();
    this.form.get("state_holder").disable();
  }

  findAddress() {
    if (this.form.get("cep_holder").value.length != 9) return;
    const cep = this.form.get("cep_holder").value.split("-").join("");
    this.cartService
      .getItemsShippingValue({
        cep,
        courses_ids: [],
      })
      .subscribe((response: any) => {
        this.form.patchValue({
          state_holder: response.address.uf,
          city_holder: response.address.city,
          neighborhood_holder: response.address.neighborhood,
          address_holder: response.address.address,
        });
      });
  }

  changePrivacityTermStatus() {
    this.terms.privacity = !this.terms.privacity;
  }

  changeCommitmentTermStatus() {
    this.terms.commitment = !this.terms.commitment;
  }

  changeImageTermStatus() {
    if (this.terms.no_image_use) {
      const checkbox = document.getElementById(
        "no_image_use"
      ) as HTMLInputElement;

      checkbox.checked = false;

      this.terms.no_image_use = false;
    }

    this.terms.image = !this.terms.image;
  }

  changeNoUseImageTermStatus() {
    if (this.terms.image) {
      const checkbox = document.getElementById("image_use") as HTMLInputElement;

      checkbox.checked = false;

      this.terms.image = false;
    }

    this.terms.no_image_use = !this.terms.no_image_use;
  }

  cleanTermsOptions() {
    const image_use_checkbox = document.getElementById(
      "image_use"
    ) as HTMLInputElement;
    const no_image_use_checkbox = document.getElementById(
      "no_image_use"
    ) as HTMLInputElement;

    image_use_checkbox.checked = false;
    no_image_use_checkbox.checked = false;
    this.terms.image = false;
    this.terms.no_image_use = false;
  }

  copyCode(formaDePagamento: string) {
    let code = '';

    switch(formaDePagamento) {
      case 'pix':
        code = this.codePix.nativeElement.textContent;
        break;
      case 'boleto':
        code = this.codeBoleto.nativeElement.textContent;
        break;
      default:
        break;
    }

    window.navigator["clipboard"].writeText(code);
    this.showMsgCodeCopied = true;

    setTimeout(() => {
      this.showMsgCodeCopied = false;
    }, 1000);
  }

  showImageTerms() {
    this.showImageTerm = true;
  }

  hideImageTerms() {
    this.showImageTerm = false;
  }

  showConfirmationOfNotUseImageModal() {
    this.showConfirmationOfNotUseImageTerm = true;
  }

  hideConfirmationOfNotUseImageModal() {
    this.showConfirmationOfNotUseImageTerm = false;
  }

  closeModalCrossData() {
    this.showModalCrossData = false;
    document.body.style.overflowY = "auto";
  }

  onFormCrossSubmited(event: FormGroup) {
    this.showModalCrossData = false;
    document.body.style.overflowY = "auto";
    this.cross_options = event;

    const data = {
      aluno: {
        nome: this.checkout_response.aluno.nome,
        email: this.checkout_response.aluno.email,
        area_especializacao: this.cross_options.area_especialization.name,
        interesse_nova_espcializacao: this.cross_options.interested_another_specialization,
        registro_profissional: this.cross_options.professional_registry,
        momento_profissional: this.cross_options.professional_moment.name,
        area_atuacao: this.cross_options.area_acting,
        outra_area_atuacao: this.cross_options.other_area_acting,
        profissao: this.checkout_response.aluno.profissao,
      },
      origem: this.checkout_response.origem,
      inscricoes: this.checkout_response.inscricoes,
    };

    this.cartCheckoutService.sendCrossData(data);
  }

  formatTicketCode(ticketCode: string) {
    let ticketFormatado = "";

    ticketCode.split("").forEach((value, index) => {
      if (index == 5 || index == 15 || index == 26) ticketFormatado += ".";
      if (
        index == 10 ||
        index == 21 ||
        index == 31 ||
        index == 32 ||
        index == 33 ||
        index == 41
      )
        ticketFormatado += " ";

      ticketFormatado += value;
    });

    return ticketFormatado;
  }

  UpdateResumeValue() {
    let installment = this.installments.find(
      (item) => item.value == this.form.get("installments").value
    );

    if (!installment) {
      const minInstallment = this.installments.reduce((i, j) => {
        return j.value < i.value ? j : i;
      }, this.installments[0]);

      installment = minInstallment;
    }

    this.resumeCart.total.label = this.formatItemValue(
      Number(installment.price)
    );
    this.resumeCart.total.value = Number(installment.price);
  }

  showCrossDataModal() {
    this.showModalCrossData = true;
  }

  addToPossibleCartAbandonment(): void {
    const { cpf, email, name, phone } = this.form.value;

    const cart: ClientCart = {
      cart_id: this.cart_id,
      id_curso: this.items[0].id,
      cpf,
      nome: name,
      telefone: phone,
      email,
      origem: "curem",
      link_checkout: `${this.siteUrl}/checkout/${this.cart_id}`,
    };

    this.coreService.addToCartAbandonment(cart).subscribe({
      next: (res) => {
        return;
      },
      error: (error) => console.error(error),
    });
  }

  sendLeadCapture() {
    const { email, name, birth, cpf, phone } = this.form.value;

    const isNameAcectable = this.form.get("name").errors == null;

    if (email && name && birth && cpf && phone && isNameAcectable)
      this.addToPossibleCartAbandonment();
  }

  selectedItem: any;

  showTerms(courseId, courseName, item) {
    let selectedcourses = [];

    this.currentTermsContent = item;

    this.items.map((course) => {
      let data = {};
      data["id"] = course.id;
      course.shippingValue > 0
        ? (data["shipping_value"] = course.shippingValue)
        : "";
      course.coupom_id ? (data["coupom_id"] = course.coupom_id) : "";
      selectedcourses.push(data);
    });

    const data = {
      name: this.form.get("name").value,
      cpf: this.form.get("cpf").value,
      email: this.form.get("email").value,
      profession: this.form.get("profession").value,
      professional_registry: "",
      cep: this.form.get("cep").value,
      address: this.form.get("address").value,
      number: this.form.get("number").value,
      complement: this.form.get("complement").value,
      neighborhood: this.form.get("neighborhood").value,
      city: this.form.get("city").value,
      uf: this.form.get("uf").value.value,
      installments: this.form.get("installments").value,
      payment_method: this.paymentMethodTypeSelected,
      courses: selectedcourses,
    };

    this.coreService.getTerms(data).subscribe((response: any) => {
      response.map((item) => {
        if (parseInt(item.course_id) == parseInt(courseId)) {
          this.currentTermsContent = item;
          this.currentTermsContent["name"] = courseName;
          this.displayTerms = true;
        }
      });
    });
  }

  closeTermsDialog(): void {
    this.displayTerms = false;
  }

  closeDialog(): void {
    this.dialog = false;
  }
}
